import React from 'react';
import { Flex } from 'reflexbox/styled-components';
import styled, { css } from 'styled-components';

import { Typograph } from '@nesto/design-system';

const StyledSection = styled(Flex)`
    ${({ theme, disabled }) =>
        disabled &&
        css`
            color: ${theme.colors.grey50};
        `}
        
    border-bottom: 1px dashed ${({ theme }) => theme.colors.grey50};

    :last-of-type {
        border-bottom: none;
    }
`;

type TitleSectionProps = { disabled?: boolean };

const TitleSection = styled(Typograph)<TitleSectionProps>`
    ${props => props.theme.fonts.Montserrat700};
    font-size: ${props => props.theme.fontSizes[3]};
    color: ${props => props.theme.brand.tertiary};
    ${({ theme, disabled }) =>
        disabled &&
        css`
            color: ${theme.colors.grey50};
            cursor: default;
        `}
`;

type SectionProps = {
    title: string;
    children: React.ReactElement;
    setInnerHtml?: true | undefined;
} & TitleSectionProps;

export const Section = ({
    title,
    children,
    disabled = false,
    setInnerHtml = undefined
}: SectionProps) => (
    <StyledSection mt={4} flexDirection="column" disabled={disabled}>
        <TitleSection
            tx={title}
            disabled={disabled}
            setInnerHtml={setInnerHtml}
        />
        {React.cloneElement(children, {
            mt: 2,
            mb: 4,
            disabled
        })}
    </StyledSection>
);
