import { AutoDownPaymentPercents } from 'constants/appConstants';

export const calculateAutomaticDownPayment = (
    propertyPrice: number,
    ownerOccupied: boolean
) => {
    if (!ownerOccupied) {
        return Math.ceil(
            propertyPrice *
                AutoDownPaymentPercents.NON_OWNER_OCCUPIED_DOWN_PAYMENT
        );
    } else {
        return Math.ceil(
            propertyPrice * AutoDownPaymentPercents.BASE_DOWN_PAYMENT
        );
    }
};
