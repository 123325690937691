import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { history } from './history';
import { ScrollReset } from 'hocs/scrollReset';
import { PrivateRouteV2 } from './PrivateRouteV2';
import { Routes } from './routes';
import { PageNotFound } from 'containers/page-not-found';
import { Login } from 'containers/login';
import { ConditionalPrivateRoute } from './ConditionalRoute';
import { FeatureFlagger } from 'components/feature-flagger';
import { NewMortgage } from 'components/get-a-quote/new-mortgage.component';
import { NewMortgageSf } from 'components/get-a-quote-sf/new-mortgage.component';
import { RefinanceSf } from 'components/get-a-quote-sf/refinance.component';
import { RenewalSf } from 'components/get-a-quote-sf/renewal.component';
import { Renewal } from 'components/get-a-quote/renewal.component';
import { Refinance } from 'components/get-a-quote/refinance.component';
import { BasePrivateRoute } from './BasePrivateRoute';
import { PublicRoute } from './PublicRoute';

const ApplicationRouting = lazy(() =>
    import('./applications.routing').then(module => ({
        default: module.ApplicationRouting
    }))
);

const Rates = lazy(() =>
    import('containers/rates').then(module => ({
        default: module.Rates
    }))
);
const NewMortgageWizard = lazy(() =>
    import('containers/new-mortgage').then(module => ({
        default: module.NewMortgageWizardContainer
    }))
);
const RenewalMortgageWizard = lazy(() =>
    import('containers/renewal-mortgage').then(module => ({
        default: module.NewRenewalMortgageWizard
    }))
);
const RefinanceMortgageWizard = lazy(() =>
    import('containers/refinance-mortgage').then(module => ({
        default: module.RefinanceMortgageWizard
    }))
);

const GetAQuote = lazy(() =>
    import('components/get-a-quote').then(module => ({
        default: module.GetAQuote
    }))
);

const SignUp = lazy(() =>
    import('containers/signup').then(module => ({
        default: module.SignUp
    }))
);
const BrokersLogin = lazy(() =>
    import('containers/brokers-login').then(module => ({
        default: module.BrokersLogin
    }))
);
const ForgotPassword = lazy(() =>
    import('containers/forgot-password').then(module => ({
        default: module.ForgotPassword
    }))
);
const ChangePassword = lazy(() =>
    import('containers/change-password').then(module => ({
        default: module.ChangePassword
    }))
);
const GetAQuoteFunnel = lazy(() =>
    import('containers/get-a-quote-funnel/get-a-quote-funnel.component').then(
        module => ({
            default: module.GetAQuoteFunnel
        })
    )
);
const Settings = lazy(() =>
    import('containers/settings/settings.view').then(module => ({
        default: module.SettingsView
    }))
);

const ProductSelectionRouting = lazy(() =>
    import('./product-selection.routing').then(module => ({
        default: module.ProductSelectionRouting
    }))
);

const AccountDeletion = lazy(() =>
    import('containers/account-deletion/account-deletion.container').then(
        module => ({
            default: module.AccountDeletion
        })
    )
);

const AccountDeleted = lazy(() =>
    import('containers/account-deletion/account-deleted.container').then(
        module => ({
            default: module.AccountDeleted
        })
    )
);

export const Routing = () => (
    <FeatureFlagger variation="anonymous-rates-page">
        {isAnonRates => (
            <ConnectedRouter history={history}>
                <ScrollReset>
                    <Switch>
                        <BasePrivateRoute
                            exact
                            path={[
                                Routes.root,
                                Routes.frenchCanadaRoot,
                                Routes.dashboard
                            ]}
                            component={() => (
                                <Redirect to={Routes.applicationSelection} />
                            )}
                        />
                        <PrivateRouteV2
                            exact
                            path={[Routes.settings]}
                            component={Settings}
                        />
                        <BasePrivateRoute
                            exact
                            path={[Routes.accountDeletion]}
                            component={AccountDeletion}
                        />
                        <PublicRoute
                            hideLogout
                            hideLogin
                            exact
                            path={[Routes.accountDeleted]}
                            component={AccountDeleted}
                        />
                        <PrivateRouteV2
                            path={Routes.productSelection}
                            component={ProductSelectionRouting}
                        />
                        <PublicRoute
                            exact
                            path={[Routes.signUp, Routes.frenchCanadaSignup]}
                            component={SignUp}
                        />
                        <PublicRoute
                            exact
                            path={[Routes.login, Routes.frenchCanadaLogin]}
                            component={Login}
                        />
                        <PublicRoute
                            exact
                            path={Routes.brokersLogin}
                            component={BrokersLogin}
                        />
                        <PublicRoute
                            exact
                            path={[
                                Routes.forgotPassword,
                                Routes.frenchCanadaForgotPassword
                            ]}
                            component={ForgotPassword}
                        />
                        <PublicRoute
                            exact
                            path={[
                                Routes.changePassword,
                                Routes.frenchCanadaChangePassword
                            ]}
                            component={ChangePassword}
                        />
                        <PublicRoute
                            exact
                            path={[
                                Routes.getAQuoteRoot,
                                Routes.frenchCanadaGetAQuoteRoot
                            ]}
                            component={GetAQuoteFunnel}
                        />
                        <PublicRoute
                            exact
                            path={[
                                Routes.getAQuoteRefiRenew,
                                Routes.frenchCanadaGetAQuoteRefiRenew
                            ]}
                            component={() => (
                                <GetAQuoteFunnel showNewMortgage={false} />
                            )}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRenewal,
                                Routes.frenchCanadaGetAQuoteRenewal
                            ]}
                            component={RenewalMortgageWizard}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRefinance,
                                Routes.frenchCanadaGetAQuoteRefinance
                            ]}
                            component={RefinanceMortgageWizard}
                        />

                        <PublicRoute
                            path={[
                                Routes.getAQuoteNewMortgage,
                                Routes.frenchCanadaGetAQuoteNewMortgage
                            ]}
                            component={NewMortgageWizard}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteUnified,
                                Routes.frenchCanadaGetAQuoteUnified
                            ]}
                            component={() => <GetAQuote />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRenewalUnified,
                                Routes.frenchCanadaGetAQuoteRenewalUnified
                            ]}
                            component={() => <Renewal />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRefinanceUnified,
                                Routes.frenchCanadaGetAQuoteRefinanceUnified
                            ]}
                            component={() => <Refinance />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteNewMortgageUnified,
                                Routes.frenchCanadaGetAQuoteNewMortgageUnified
                            ]}
                            component={() => <NewMortgage />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteNewMortgageUnifiedSf,
                                Routes.frenchCanadaGetAQuoteNewMortgageUnifiedSf
                            ]}
                            component={() => <NewMortgageSf />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRefinanceUnifiedSF,
                                Routes.frenchCanadaGetAQuoteRefinanceUnifiedSf
                            ]}
                            component={() => <RefinanceSf />}
                        />
                        <PublicRoute
                            path={[
                                Routes.getAQuoteRenewalUnifiedSf,
                                Routes.frenchCanadaGetAQuoteRenewalUnifiedSf
                            ]}
                            component={() => <RenewalSf />}
                        />
                        <ConditionalPrivateRoute
                            exact
                            condition={isAnonRates}
                            path={Routes.rates}
                            component={Rates}
                        />
                        <BasePrivateRoute
                            path={Routes.application}
                            component={ApplicationRouting}
                        />
                        <PublicRoute component={PageNotFound} />
                    </Switch>
                </ScrollReset>
            </ConnectedRouter>
        )}
    </FeatureFlagger>
);
