import React, { useState, useEffect } from 'react';
import { Flex } from 'reflexbox/styled-components';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Actions } from 'reducers/mortgage-wizards.redux';
import { WIZARDS, RENEWAL_SCHEDULE } from 'constants/appConstants';
import { Typograph } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { SelectField, CurrencyField } from 'components/fields';
import { TextField } from 'components/fields/text-input/text-input.component';
import { maxLength, validateRenewalMinimum } from 'lib/validators';
import { LENDER_SELECT_OPTIONS } from 'constants/lenderConstants';
import { isLoggedIn } from 'reducers/auth.selectors';
import {
    PopupErrorMessage,
    ModalState
} from 'components/get-a-quote/minimum-financing-message.component';
import { DOWN_PAYMENT_RATIO } from 'constants/ratesConstants';
import { PageWrapperGaqSf } from 'components/get-a-quote-sf/layout';
import { Section } from 'components/get-a-quote/section-component';
import {
    validateUnderwater95,
    validateUnderwater80
} from 'components/get-a-quote/validators';
import { useQueryString } from 'utils/hooks';
import { MortgageFormValues } from 'types/mortgage.interface';
import { getWizardInitialValues } from 'reducers/mortgage-wizards.selectors';
import { Actions as AccountActions } from 'reducers/account.redux';

type StateProps = {
    initialValues: {};
};

type DispatchProps = {
    submitForm: () => void;
    analyticsQuestionViewed: (currentStep: string, index: number) => void;
    analyticsQuestionAnswered: (
        currentStep: string,
        index: number,
        value: any
    ) => void;
};

const RenewalView = ({
    change,
    ...rest
}: StateProps & DispatchProps & InjectedFormProps) => {
    const [modal, setModal] = useState<ModalState | null>(null);
    const [logoutUser, setLogoutUser] = useState(true);
    const formValues = useSelector(
        getFormValues(WIZARDS.RENEWAL_MORTGAGE_QUOTE)
    ) as MortgageFormValues;
    const isUserLoggedIn = useSelector(isLoggedIn);
    const dispatch = useDispatch();

    const {
        propertyPrice,
        purpose,
        lender,
        renewalSchedule,
        mortgageAmount,
        firstName,
        lastName,
        phone,
        province,
        email
    } = useQueryString();

    useEffect(() => {
        // logout the user first
        if (isUserLoggedIn && logoutUser) {
            setLogoutUser(false);
            dispatch(AccountActions.logout());
        }

        dispatch(
            Actions.updateInitialValues({
                propertyValue: +propertyPrice,
                ownerOccupied: purpose?.toUpperCase() === 'OWNER_OCCUPIED',
                lender,
                renewalSchedule,
                mortgageAmount: +mortgageAmount,
                contact: {
                    firstName,
                    lastName,
                    phone,
                    province,
                    email
                }
            })
        );
    }, [
        propertyPrice,
        purpose,
        lender,
        renewalSchedule,
        mortgageAmount,
        firstName,
        lastName,
        phone,
        province,
        email,
        isUserLoggedIn,
        dispatch,
        logoutUser,
        setLogoutUser
    ]);

    const submitHandler = () => {
        const isMinimumRequirement = validateRenewalMinimum(
            formValues?.mortgageAmount
        );
        if (!isMinimumRequirement) {
            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });
            return;
        }

        const isMortgageUnderwater95 = validateUnderwater95(
            formValues?.propertyValue,
            formValues?.mortgageAmount
        );
        if (isMortgageUnderwater95) {
            setModal({
                title: 'getAQuote.underwater95Title',
                content: 'getAQuote.underwater95Text'
            });
            return;
        }

        const isMortgageUnderwater80 = validateUnderwater80(
            formValues?.downPaymentRatio,
            formValues?.propertyValue,
            formValues?.mortgageAmount
        );
        if (isMortgageUnderwater80) {
            setModal({
                title: 'getAQuote.underwater80Title',
                content: 'getAQuote.underwater80Text'
            });
            return;
        }

        setLogoutUser(false);

        dispatch(Actions.submitRenewal(WIZARDS.RENEWAL_MORTGAGE_QUOTE));
    };

    return (
        <PageWrapperGaqSf
            id="renewal"
            title="getAQuote.browserNewMortgageTitle"
            onSubmit={submitHandler}
            change={change}
            {...rest}
        >
            <Typograph
                tx="getAQuote.renewalConfirmGaqValues"
                mt={0}
                fontSize={22}
                fontWeight={700}
            />
            <Section title="getAQuoteRenewal.live">
                <RadioGroupField name="ownerOccupied" />
            </Section>
            <Section title="getAQuoteRenewal.propertyValue">
                <CurrencyField
                    placeholder="propertyValue"
                    isRequired
                    name="propertyValue"
                />
            </Section>
            <Section title="getAQuote.mortgageBalance">
                <CurrencyField
                    placeholder="mortgageBalance"
                    isRequired
                    name="mortgageAmount"
                    maxValue={formValues?.propertyValue}
                />
            </Section>
            <Section title="getAQuote.insuredByCmhc" setInnerHtml={true}>
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="downPaymentRatio"
                        placeholder="select"
                        isRequired
                        options={DOWN_PAYMENT_RATIO}
                    />
                    <Typograph
                        mt={[2, 0]}
                        ml={[0, 2]}
                        tx="getAQuote.insuredByCmhcText"
                    />
                </Flex>
            </Section>
            <Section title="getAQuoteRenewal.currentMortgageLender">
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="lender"
                        placeholder="lender"
                        options={LENDER_SELECT_OPTIONS}
                        sort
                        isRequired
                    />
                    {formValues?.lender === 'OTHER' && (
                        <TextField
                            mt={[2, 0]}
                            ml={[0, 2]}
                            name="lenderOther"
                            isRequired
                            validate={[maxLength(36)]}
                            placeholder="getAQuoteRenewal.lenderOther"
                        />
                    )}
                </Flex>
            </Section>
            <Section title="getAQuoteRenewal.renewalSchedule">
                <SelectField
                    name="renewalSchedule"
                    placeholder="select"
                    options={RENEWAL_SCHEDULE}
                    isRequired
                />
            </Section>

            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapperGaqSf>
    );
};

export const RenewalSf = compose<React.FC>(
    connect<StateProps>(state => ({
        initialValues: getWizardInitialValues(state)
    })),
    reduxForm({
        form: WIZARDS.RENEWAL_MORTGAGE_QUOTE,
        enableReinitialize: true, // async region fetch may come after component rendering
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    })
)(RenewalView);
