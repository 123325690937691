import { change, Field } from 'redux-form';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'components/inputs/checkbox';

export const PartnersEmailConsentCheckbox = ({
    formName,
    leadDistributeConsentAgreement = 'leadDistributeConsentAgreement',
    emailConsent = 'emailConsent'
}) => {
    const dispatch = useDispatch();

    return (
        <Field
            name={leadDistributeConsentAgreement}
            component={Checkbox as any}
            props={{
                label: 'getAQuote.shortPartnerAgreementAndEmail_V3',
                copyText: 'readOurPolicy_V2',
                fontSize: '10px'
            }}
            type="checkbox"
            onChange={(_event, newValue) => {
                dispatch(change(formName, emailConsent, newValue));
            }}
        />
    );
};
