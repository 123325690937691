import React from 'react';
import { Flex, Box } from 'reflexbox/styled-components';

import { ModalManager } from 'components/modal-manager';
import Modal from 'components/modal';
import closeIcon from 'assets/media/icons/button-close@2x.png';
import { IconComponent as Icon } from 'components/icon';
import { Typograph } from 'components/typograph';
import { Card } from 'components/card';
import { ThemeProps, withTheme } from 'styled-components';
import { Theme } from '@nesto/themes';

export type ModalState = {
    title?: string;
    content?: string;
};

type ModalProps = {
    onClose: () => void;
} & ModalState;

const ErrorMessageModal = withTheme(
    ({ theme, onClose, title, content }: ModalProps & ThemeProps<Theme>) => (
        <Modal>
            <Flex
                width="100%"
                flexDirection="column"
                alignItems="center"
                mt="10%"
                px={3}
            >
                <Card width={theme.contentWidths}>
                    <Flex flexDirection="row" justifyContent="flex-end">
                        <Box>
                            <Icon
                                width="30px"
                                onClick={onClose}
                                src={closeIcon}
                                alt="button-close"
                                style={{
                                    cursor: 'pointer'
                                }}
                            />
                        </Box>
                    </Flex>
                    <Box mt={[2]}>
                        <Typograph
                            fontSize={20}
                            fontWeight={700}
                            tx={title}
                            setInnerHtml
                        />
                        <Typograph fontSize={2} mt={20} mb={50} tx={content} />
                    </Box>
                </Card>
            </Flex>
        </Modal>
    )
);

type Props = {
    showModal: boolean;
    onCloseComplete: () => void;
} & ModalState;

export const PopupErrorMessage = ({
    showModal,
    onCloseComplete,
    title,
    content
}: Props) => (
    <ModalManager isShown={showModal} onCloseComplete={onCloseComplete}>
        {({ close }: { close: () => void }) => (
            <ErrorMessageModal
                title={title}
                content={content}
                onClose={close}
            />
        )}
    </ModalManager>
);
