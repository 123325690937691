import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import styled, { ThemeProps } from 'styled-components/macro';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { useQueryString } from 'utils/hooks';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Theme } from '@nesto/themes';
import { Actions } from 'reducers/mortgage-wizards.redux';
import {
    WIZARDS,
    APPLICATION_TIMING,
    TimingAcceptanceDates
} from 'constants/appConstants';
import { Typograph } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { PROPERTY_TYPE } from 'constants/ratesConstants';
import { SelectField, CurrencyField } from 'components/fields';
import {
    newMortgageDownpaymentInformation,
    newMortgageDownPaymentPercentage
} from 'reducers/mortgage-wizards.selectors';
import {
    BubbleMessage,
    BubbleType,
    TopArrowPositions
} from 'components/bubble-message/bubble-message.component';
import { ExclamationSvg } from 'assets/media/icons/ExclamationSvg';
import { DateField } from 'components/fields';
import { isLoggedIn } from 'reducers/auth.selectors';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import {
    PopupErrorMessage,
    ModalState
} from 'components/get-a-quote/minimum-financing-message.component';
import * as Validators from 'lib/validators';
import { getWizardInitialValues } from 'reducers/mortgage-wizards.selectors';
import { Timings } from 'types/application/application';
import { calculateAutomaticDownPayment } from 'components/get-a-quote/get-a-quote.utils';
import { PageWrapperGaqSf } from 'components/get-a-quote-sf/layout';
import { Section } from 'components/get-a-quote/section-component';
import { MortgageFormValues } from 'types/mortgage.interface';
import { useTheme } from 'utils/hooks';
import { Actions as AccountActions } from 'reducers/account.redux';

type StateProps = {
    initialValues: {};
};

const Percentage = styled.span`
    font-size: ${props => props.theme.fontSizes[4]};

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        font-size: ${props => props.theme.fontSizes[3]};
    }
`;

const PropertyValueText = styled(Typograph)`
    font-size: ${props => props.theme.fontSizes[2]};

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        font-size: 10px;
    }
`;

const NewMortgageView = ({
    change,
    ...rest
}: InjectedFormProps & ThemeProps<Theme>) => {
    const [modal, setModal] = useState<ModalState | null>(null);
    const [logoutUser, setLogoutUser] = useState(true);
    const isAutoApplyDownPaymentActive = useFeatureFlag('auto-apply-dp');
    const hideAcceptanceQuestion = useFeatureFlag('hide-acceptance-question');
    const dispatch = useDispatch();
    const theme = useTheme();

    // const initialValues = useSelector(getWizardInitialValues);
    const formValues = useSelector(
        getFormValues(WIZARDS.NEW_MORTGAGE_QUOTE)
    ) as MortgageFormValues;

    const downpaymentPercentage = useSelector(newMortgageDownPaymentPercentage);
    const downpaymentInfo = useSelector(newMortgageDownpaymentInformation);
    const isUserLoggedIn = useSelector(isLoggedIn);

    const {
        propertyPrice,
        purpose,
        downPaymentAmount,
        amortization,
        mortgageAmount,
        propertyType,
        timing,
        firstName,
        lastName,
        phone,
        province,
        email
    } = useQueryString();

    useEffect(() => {
        // logout the user first
        if (isUserLoggedIn && logoutUser) {
            setLogoutUser(false);
            dispatch(AccountActions.logout());
        }

        dispatch(
            Actions.updateInitialValues({
                propertyType,
                propertyValue: +propertyPrice,
                ownerOccupied: purpose?.toUpperCase() === 'OWNER_OCCUPIED',
                downPaymentAmount: +downPaymentAmount,
                amortization,
                mortgageAmount: +mortgageAmount,
                timing,
                contact: {
                    firstName,
                    lastName,
                    phone,
                    province,
                    email
                }
            })
        );
    }, [
        propertyPrice,
        purpose,
        downPaymentAmount,
        amortization,
        mortgageAmount,
        propertyType,
        timing,
        firstName,
        lastName,
        phone,
        province,
        email,
        isUserLoggedIn,
        dispatch,
        logoutUser,
        setLogoutUser
    ]);

    const memorizedValidator = useCallback(
        () => (downpaymentInfo ? 'validation.errors.isRequired' : undefined),
        [downpaymentInfo]
    );

    const submitHandler = () => {
        const isMortgageValid = Validators.validateNewMortgage(
            formValues?.propertyValue,
            formValues?.downPaymentAmount
        );

        if (!isMortgageValid) {
            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });

            return;
        }

        setLogoutUser(false);

        dispatch(Actions.submitNew(WIZARDS.NEW_MORTGAGE_QUOTE));
    };

    const onPropertyValueBlur = useCallback(() => {
        if (formValues?.propertyValue > 0) {
            if (isAutoApplyDownPaymentActive) {
                change(
                    'downPaymentAmount',
                    calculateAutomaticDownPayment(
                        formValues.propertyValue,
                        formValues.ownerOccupied
                    )
                );
            }
        }
    }, [
        formValues?.ownerOccupied,
        formValues?.propertyValue,
        change,
        isAutoApplyDownPaymentActive
    ]);

    return (
        <PageWrapperGaqSf
            id="new-mortgage"
            title="getAQuote.browserNewMortgageTitle"
            onSubmit={submitHandler}
            change={change}
            {...rest}
        >
            <Typograph
                tx="getAQuote.newMortgageConfirmGaqValues"
                mt={0}
                fontSize={22}
                fontWeight={700}
            />

            <Section title="getAQuote.live">
                <RadioGroupField name="ownerOccupied" />
            </Section>
            <Section title="propertyType">
                <SelectField
                    name="propertyType"
                    placeholder="application.targetProperty.propertyType"
                    isRequired
                    options={PROPERTY_TYPE}
                />
            </Section>
            <Section title="getAQuote.purchasePrice">
                <CurrencyField
                    placeholder="prequalify.propertyPrice"
                    isRequired
                    name="propertyValue"
                    onBlur={onPropertyValueBlur}
                />
            </Section>
            <Section title="downPaymentAmountQuestionTitle">
                <Flex flexDirection="column">
                    <Flex
                        width={1}
                        flexDirection={['column', 'row']}
                        alignItems="center"
                    >
                        <Box width={[1, 0.6]} pr={[0, 2]}>
                            <CurrencyField
                                mt={0}
                                placeholder="downPayment"
                                isRequired
                                disabled={
                                    !formValues?.propertyType ||
                                    !formValues?.propertyValue
                                }
                                name="downPaymentAmount"
                                maxValue={formValues?.propertyValue}
                                validate={[memorizedValidator]}
                            />
                        </Box>
                        <Box width={[1, 0.4]} mt={[2, 0]}>
                            <Percentage data-test-id="downpaymentPercentage">
                                {downpaymentPercentage}%
                            </Percentage>
                            <PropertyValueText tx="ofPropertyValue" />
                        </Box>
                    </Flex>
                    {downpaymentInfo && (
                        <Flex mt={2} flexDirection="row" alignItems="center">
                            <BubbleMessage
                                text={downpaymentInfo}
                                topArrowPosition={TopArrowPositions.left}
                                icon={
                                    <ExclamationSvg
                                        fillColor={theme.colors.sunsetOrange}
                                    />
                                }
                                bubbleType={BubbleType.error}
                            />
                        </Flex>
                    )}
                </Flex>
            </Section>
            <Section title="getAQuote.purchaseProx">
                <SelectField
                    name="timing"
                    placeholder="select"
                    isRequired
                    options={APPLICATION_TIMING}
                    onChange={value => {
                        if (hideAcceptanceQuestion && value) {
                            change(
                                'acceptanceDate',
                                TimingAcceptanceDates[value]
                            );
                        }
                    }}
                />
            </Section>
            {!hideAcceptanceQuestion && (
                <Section
                    title={
                        formValues?.timing !== Timings.MADE_OFFER
                            ? 'getAQuote.dateMakingOffer'
                            : 'getAQuote.acceptanceDateMadeOffer'
                    }
                >
                    <DateField
                        name="acceptanceDate"
                        isRequired
                        showMonthYear={
                            formValues?.timing !== Timings.MADE_OFFER
                        }
                        validate={
                            formValues?.timing !== Timings.MADE_OFFER
                                ? [Validators.isCurrentMonthOrFuture]
                                : [
                                      Validators.isTodayOrPastDate,
                                      Validators.dateExceedsOneYear(new Date())
                                  ]
                        }
                    />
                </Section>
            )}
            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapperGaqSf>
    );
};

export const NewMortgageSf = compose<React.FC>(
    connect<StateProps>((state: any) => ({
        initialValues: getWizardInitialValues(state)
    })),
    reduxForm({
        form: WIZARDS.NEW_MORTGAGE_QUOTE,
        enableReinitialize: true, // async region fetch may come after component rendering
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    })
)(NewMortgageView);
