import React from 'react';
import { withTheme } from 'styled-components/macro';

type Props = {
    fillColor?: string;
    width?: number;
    height?: number;
    theme?: any;
};

const TickView = ({ fillColor, theme, width = 20, height = 20 }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
        viewBox="0 0 20 20"
    >
        <defs>
            <path
                id="zgera"
                d="M535.303 2739.76a1.249 1.249 0 0 1-1.767 0l-1.92-1.918a1.25 1.25 0 0 1 1.768-1.769l1.035 1.036 4.205-4.205a1.249 1.249 0 1 1 1.768 1.768z"
            />
            <path id="zgerc" d="M528 2744v-16h16v16z" />
            <clipPath id="zgerb">
                <use xlinkHref="#zgera" />
            </clipPath>
        </defs>
        <g>
            <g transform="translate(-526 -2726)">
                <g />
                <g clipPath="url(#zgerb)">
                    <g>
                        <use
                            fill={fillColor || theme.brand.accent}
                            xlinkHref="#zgerc"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const Tick = withTheme(TickView);
