import { RadioGroup, Radio } from 'react-radio-group';

import {
    Field,
    BaseFieldProps,
    Validator,
    Normalizer,
    WrappedFieldProps
} from 'redux-form';
import { Box, Flex } from 'reflexbox/styled-components';
import { WidthProps, SpaceProps } from 'styled-system';
import styled, { css } from 'styled-components';

import { RadioButton } from 'components/buttons/button';
import { TypographI18nKeys, Typograph } from 'components/typograph';
import { InputError } from 'components/inputs/input-error/input-error.component';
import * as Validators from 'lib/validators';
import { theme } from 'theme';
import { BOOLEAN_YES_NO_OPTIONS } from 'constants/appConstants';
import * as Normalizers from 'lib/normalizers';
import { readOnlyInput } from 'components/inputs/shared/styles';

const StyledRadio = styled(Radio)`
    width: 100%;
    display: block;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
`;

const StyledRadioGroupLabel = styled(Box)`
    ${props => props.theme.fonts.MontserratSemiBold};
    font-size: ${props => props.theme.fontSizes[2]};
    color: ${props => props.theme.colors.coldGray};
`;

const RadioGroupWrapper = styled(Flex)`
    ${readOnlyInput}
`;

const radioHorizontal = css`
    margin-right: ${props => props.theme.space[2]}px;
    padding-right: ${props => props.theme.space[2]}px;
    padding-left: 0;

    &:after {
        content: '';
        width: 1px;
        border: 0.5px solid ${props => props.theme.colors.quillGray};
        display: inline-block;
        position: absolute;
        right: 0;
        height: 20px;
        top: calc(100% - 24px);
    }

    &:last-of-type {
        padding-right: 0;

        &:after {
            content: none;
        }
    }

    &:last-child {
        margin-right: 0;
    }
`;

const radioVertical = css`
    margin-bottom: ${props => props.theme.space[2]}px;
`;

const RadioWrapper: any = styled(RadioButton)`
    position: relative;
    ${({ direction }: Pick<RadioGroupProps, 'direction'>) =>
        direction === 'HORIZONTAL' ? radioHorizontal : radioVertical}
`;

type Value = boolean | string;
type RadioGroupProps = {
    options: { label: TypographI18nKeys; value: Value }[];
    input: {
        value: any;
    };
    groupLabel?: TypographI18nKeys;
    handleOnClick?: (value: Value) => void;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    id?: string;
    showErrorOnTouched?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
};

export const returnClass = meta => {
    if (meta.touched && meta.error) {
        return 'error';
    }

    if (meta.touched && meta.valid) {
        return 'valid';
    }

    return 'default';
};

export const RadioOptions = ({
    options,
    input,
    meta,
    groupLabel,
    readOnly,
    disabled,
    // This is a hack to get around RadioGroup's onChange & selectedValue
    // props not working with redux-form.
    //
    // If you supply onChange to RadioGroup, it'll block
    // redux-form from updating the Field.
    //
    // Trying to use selectedValue has redux-form throwing errors
    // about incorrect checked props being supplied to fields
    handleOnClick,
    direction = 'HORIZONTAL',
    id = '',
    showErrorOnTouched = false
}: RadioGroupProps & WrappedFieldProps) => {
    const classStyle = returnClass(meta);
    return (
        <RadioGroupWrapper
            theme={theme}
            flexDirection="column"
            css={
                readOnly && {
                    background: '#f8f8f8',
                    padding: '20px',
                    borderRadius: '6px'
                }
            }
            tabIndex={readOnly ? 0 : undefined}
            readOnly={readOnly}
        >
            <Flex flexDirection={['column', 'row']} alignItems="center">
                {groupLabel && (
                    <Flex flex={1} width={['100%', 'auto']} mr={[0, 1]}>
                        <StyledRadioGroupLabel
                            data-test-id={`label-${id ? id : input.name}`}
                        >
                            <Typograph tx={groupLabel} setInnerHtml />
                        </StyledRadioGroupLabel>
                    </Flex>
                )}

                <Box width={['100%', 'auto']} mt={[1, 0]}>
                    <RadioGroup {...input}>
                        <Flex
                            wrap="wrap"
                            alignItems={
                                direction === 'HORIZONTAL'
                                    ? 'center'
                                    : 'flex-start'
                            }
                            flexDirection={
                                direction === 'HORIZONTAL' ? 'row' : 'column'
                            }
                        >
                            {options.map(({ label, value }) =>
                                readOnly ? (
                                    value === input.value && (
                                        <StyledRadioGroupLabel
                                            key={`label-${value}`}
                                        >
                                            <Typograph
                                                tx={label}
                                                setInnerHtml
                                            />
                                        </StyledRadioGroupLabel>
                                    )
                                ) : (
                                    <RadioWrapper
                                        key={`label-${value}`}
                                        active={input.value === value}
                                        checkColor={theme.colors.coldGray}
                                        borderColor={
                                            classStyle === 'error'
                                                ? theme.input.error.borderColor
                                                : theme.borderColor.default
                                        }
                                        direction={direction}
                                        disabled={disabled}
                                    >
                                        <StyledRadioGroupLabel>
                                            <Typograph
                                                tx={label}
                                                setInnerHtml
                                            />
                                        </StyledRadioGroupLabel>

                                        <StyledRadio
                                            data-test-id={id ? id : input.name}
                                            value={value}
                                            disabled={disabled}
                                            onClick={() => {
                                                handleOnClick &&
                                                    handleOnClick(value);
                                            }}
                                        />
                                    </RadioWrapper>
                                )
                            )}
                        </Flex>
                    </RadioGroup>
                </Box>
            </Flex>
            {meta && (
                <Flex>
                    <InputError
                        meta={meta}
                        name={input.name}
                        showErrorOnTouched={showErrorOnTouched}
                    />
                </Flex>
            )}
        </RadioGroupWrapper>
    );
};

const noOp = () => undefined;

type FieldProps = {
    name: string;
    options?: Array<{ label: TypographI18nKeys; value: any }>;
    label?: TypographI18nKeys;
    isRequired?: boolean;
    validate?: Validator[];
    normalize?: Normalizer;
    format?: Normalizer;
    direction?: 'HORIZONTAL' | 'VERTICAL';
    showErrorOnTouched?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
};

type StyledProps = WidthProps & SpaceProps;

const RadioGroupField = ({
    name,
    options = BOOLEAN_YES_NO_OPTIONS,
    label,
    validate = [noOp],
    normalize = Normalizers.normalizeToBool,
    format,
    isRequired = false,
    onBlur,
    onFocus,
    direction,
    showErrorOnTouched = false,
    readOnly,
    disabled,
    ...rest
}: FieldProps & BaseFieldProps & StyledProps) => (
    <Box my={[3]} width="100%" {...rest}>
        <Field
            component={RadioOptions as any}
            name={name}
            options={options}
            normalize={normalize}
            readOnly={readOnly}
            disabled={disabled || readOnly}
            format={format}
            groupLabel={label}
            validate={[isRequired ? Validators.isRequired : noOp, ...validate]}
            direction={direction}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    </Box>
);

export { RadioGroupField };
