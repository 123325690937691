import * as React from 'react';
import styled, { withTheme } from 'styled-components/macro';
import { space, width, SpaceProps, WidthProps } from 'styled-system';
import { Flex } from 'reflexbox/styled-components';
import { Typograph, TypographI18nKeys } from 'components/typograph';

export enum BubbleType {
    default = 'DEFAULT',
    error = 'ERROR',
    warning = 'WARNING'
}

export enum TopArrowPositions {
    left = 'LEFT',
    right = 'RIGHT',
    center = 'CENTER'
}

type Props = {
    id?: string;
    bubbleType?: BubbleType;
    text?: TypographI18nKeys;
    topArrowPosition?: TopArrowPositions;
    icon?: any;
    arrowProps?: any;
    theme?: any;
    noArrow?: boolean;
    children?: React.ReactNode;
};

type BorderProp = {
    theme: any;
    bubbleType?: BubbleType;
};
export const BorderColors = (theme, bubbleType): BorderProp => {
    const BorderColor = {
        [BubbleType.default]: theme.borderColor.default,
        [BubbleType.error]: theme.application.warning,
        [BubbleType.warning]: theme.brand.accent
    };

    return BorderColor[bubbleType ? bubbleType : BubbleType.default];
};

const Wrapper = styled(Flex)`
    width: 100%;
    position: relative;
    border: 1px solid
        ${({ bubbleType, theme }) => BorderColors(theme, bubbleType)};
    border-radius: ${props => props.theme.borderRadius};
    font-family: ${props => props.theme.fontFamily};
    padding: ${props => props.theme.space[2]}px;
    font-size: ${props => props.theme.fontSizes[1]};
    min-width: 100px;
    background-color: ${props => props.theme.colors.white};
    align-items: center;
    margin-top: 12px;
    ${space}
    ${width}
`;

const TopArrowPosition = {
    [TopArrowPositions.left]: 'left: 15%;',
    [TopArrowPositions.right]: 'right 15%;',
    [TopArrowPositions.center]: 'left 50%;'
};

const TopArrow = styled(Flex)`
    position: absolute;
    ${({ topArrowPosition }) => TopArrowPosition[topArrowPosition]}
    top: -1px;
    &:before {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        top: -7px;
        left: -7px;
        border-top: 1px solid
            ${({ bubbleType, theme }) => BorderColors(theme, bubbleType)};
        border-left: 1px solid
            ${({ bubbleType, theme }) => BorderColors(theme, bubbleType)};
        transform: rotateZ(45deg);
        background-color: white;
    }
    &:after {
        content: '';
        width: 18px;
        height: 1px;
        position: absolute;
        left: -9px;
        background: ${props => props.theme.colors.white};
    }
    ${({ arrowProps }) => arrowProps}
`;

const Container = styled(Flex)`
    width: inherit;
    height: 100%;
    ${space};
    ${width};
`;

const BubbleMessageView = ({
    bubbleType = BubbleType.default,
    text,
    topArrowPosition = TopArrowPositions.right,
    icon,
    id = 'default',
    arrowProps,
    theme,
    noArrow = false,
    children,
    ...props
}: Props & SpaceProps & WidthProps) => {
    const withArrow = !noArrow;

    return (
        <Wrapper
            {...props}
            bubbleType={bubbleType}
            data-test-id={`bubble-message-wrapper-${id}`}
            alignItems="center"
        >
            {withArrow && (
                <TopArrow
                    topArrowPosition={topArrowPosition}
                    bubbleType={bubbleType}
                    arrowProps={arrowProps}
                />
            )}
            {icon && <Flex mr={theme.space[2]}>{icon}</Flex>}

            <Container data-test-id={`bubble-message-content-${id}`}>
                {children || <Typograph fontSize={1} tx={text} />}
            </Container>
        </Wrapper>
    );
};

export const BubbleMessage = withTheme(BubbleMessageView);
