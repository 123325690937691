import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Box } from 'reflexbox/styled-components';
import styled, { ThemeProps, withTheme } from 'styled-components/macro';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { useQueryString } from 'utils/hooks';

import { Theme } from '@nesto/themes';
import { Actions } from 'reducers/mortgage-wizards.redux';
import {
    WIZARDS,
    APPLICATION_TIMING,
    TimingAcceptanceDates
} from 'constants/appConstants';
import { Typograph, TypographI18nKeys } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { PROPERTY_TYPE } from 'constants/ratesConstants';
import { SelectField, CurrencyField } from 'components/fields';
import {
    newMortgageDownpaymentInformation,
    newMortgageDownPaymentPercentage
} from 'reducers/mortgage-wizards.selectors';
import {
    BubbleMessage,
    BubbleType,
    TopArrowPositions
} from 'components/bubble-message/bubble-message.component';
import { ExclamationSvg } from 'assets/media/icons/ExclamationSvg';
import { DateField } from 'components/fields';
import { isLoggedIn } from 'reducers/auth.selectors';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import {
    PopupErrorMessage,
    ModalState
} from './minimum-financing-message.component';
import * as Validators from 'lib/validators';
import { getWizardInitialValues } from 'reducers/mortgage-wizards.selectors';
import { Timings } from 'types/application/application';
import { calculateAutomaticDownPayment } from './get-a-quote.utils';
import { PageWrapper } from './layout';
import { Section } from './section-component';
import { MortgageFormValues } from 'types/mortgage.interface';

type StateProps = {
    formValues: MortgageFormValues;
    downpaymentInfo?: TypographI18nKeys | undefined;
    downpaymentPercentage: number;
    isUserLoggedIn: boolean;
};

type DispatchProps = {
    updateInitialValues: (initialValues: {}) => void;
    submitForm: () => void;
    analyticsQuestionViewed: (currentStep: string, index: number) => void;
    analyticsQuestionAnswered: (
        currentStep: string,
        index: number,
        value: any
    ) => void;
};

const Percentage = styled.span`
    font-size: ${props => props.theme.fontSizes[4]};

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        font-size: ${props => props.theme.fontSizes[3]};
    }
`;

const PropertyValueText = styled(Typograph)`
    font-size: ${props => props.theme.fontSizes[2]};

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        font-size: 10px;
    }
`;

const NewMortgageView = ({
    formValues = {} as MortgageFormValues,
    submitForm,
    downpaymentPercentage,
    downpaymentInfo,
    isUserLoggedIn,
    analyticsQuestionAnswered,
    analyticsQuestionViewed,
    updateInitialValues,
    theme,
    change,
    ...rest
}: StateProps & DispatchProps & InjectedFormProps & ThemeProps<Theme>) => {
    const [showContact, setShowContact] = useState(false);
    const [modal, setModal] = useState<ModalState | null>(null);
    const isAnonRates = useFeatureFlag('anonymous-rates-page');
    const isAutoApplyDownPaymentActive = useFeatureFlag('auto-apply-dp');
    const hideAcceptanceQuestion = useFeatureFlag('hide-acceptance-question');

    const {
        propertyPrice,
        purpose,
        downPaymentAmount,
        amortization,
        mortgageAmount
    } = useQueryString();

    useEffect(() => {
        if (propertyPrice) {
            updateInitialValues({
                propertyValue: +propertyPrice,
                ownerOccupied: purpose.toUpperCase() === 'OWNER_OCCUPIED',
                downPaymentAmount: +downPaymentAmount,
                amortization,
                mortgageAmount: +mortgageAmount
            });
        }
    }, [
        propertyPrice,
        updateInitialValues,
        purpose,
        downPaymentAmount,
        amortization,
        mortgageAmount
    ]);

    const memorizedValidator = useCallback(
        () => (downpaymentInfo ? 'validation.errors.isRequired' : undefined),
        [downpaymentInfo]
    );

    const submitHandler = () => {
        const isMortgageValid = Validators.validateNewMortgage(
            formValues?.propertyValue,
            formValues?.downPaymentAmount
        );

        if (!isMortgageValid) {
            analyticsQuestionViewed('minimum_requirement', 5);

            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });

            return;
        }

        if (!isUserLoggedIn && !isAnonRates && !showContact) {
            analyticsQuestionViewed('contact', 8);
            setShowContact(true);
            return;
        }

        if (showContact) {
            analyticsQuestionAnswered('contact', 8, formValues);
        }

        submitForm();
    };

    const onPropertyValueBlur = useCallback(() => {
        if (formValues?.propertyValue > 0) {
            analyticsQuestionAnswered(
                'propertyValue',
                3,
                formValues.propertyValue
            );

            if (isAutoApplyDownPaymentActive) {
                change(
                    'downPaymentAmount',
                    calculateAutomaticDownPayment(
                        formValues.propertyValue,
                        formValues.ownerOccupied
                    )
                );
            }
        }
    }, [
        analyticsQuestionAnswered,
        formValues?.ownerOccupied,
        formValues?.propertyValue,
        change,
        isAutoApplyDownPaymentActive
    ]);

    return (
        <PageWrapper
            id="new-mortgage"
            title="getAQuote.browserNewMortgageTitle"
            showContact={showContact}
            onSubmit={submitHandler}
            change={change}
            {...rest}
        >
            <Section title="getAQuote.live">
                <RadioGroupField
                    name="ownerOccupied"
                    onChange={input => {
                        analyticsQuestionAnswered(
                            'ownerOccupied',
                            1,
                            input?.target.value
                        );
                    }}
                    onFocus={() =>
                        formValues?.ownerOccupied === undefined &&
                        analyticsQuestionViewed('ownerOccupied', 1)
                    }
                />
            </Section>
            <Section
                title="propertyType"
                disabled={formValues?.ownerOccupied === undefined}
            >
                <SelectField
                    name="propertyType"
                    placeholder="application.targetProperty.propertyType"
                    isRequired
                    options={PROPERTY_TYPE}
                    onChange={input => {
                        analyticsQuestionAnswered('propertyType', 2, input);
                    }}
                    onFocus={() =>
                        formValues?.propertyType === undefined &&
                        analyticsQuestionViewed('propertyType', 2)
                    }
                />
            </Section>
            <Section
                title="getAQuote.purchasePrice"
                disabled={!formValues?.propertyType}
            >
                <CurrencyField
                    placeholder="prequalify.propertyPrice"
                    isRequired
                    name="propertyValue"
                    onBlur={onPropertyValueBlur}
                    onFocus={() =>
                        formValues?.propertyValue === undefined &&
                        analyticsQuestionViewed('propertyValue', 3)
                    }
                />
            </Section>
            <Section
                title="downPaymentAmountQuestionTitle"
                disabled={
                    !formValues?.propertyType || !formValues?.propertyValue
                }
            >
                <Flex flexDirection="column">
                    <Flex
                        width={1}
                        flexDirection={['column', 'row']}
                        alignItems="center"
                    >
                        <Box width={[1, 0.6]} pr={[0, 2]}>
                            <CurrencyField
                                mt={0}
                                placeholder="downPayment"
                                isRequired
                                disabled={
                                    !formValues?.propertyType ||
                                    !formValues?.propertyValue
                                }
                                name="downPaymentAmount"
                                maxValue={formValues?.propertyValue}
                                validate={[memorizedValidator]}
                                onBlur={() =>
                                    formValues?.downPaymentAmount > 0 &&
                                    analyticsQuestionAnswered(
                                        'downPayment',
                                        4,
                                        formValues.downPaymentAmount
                                    )
                                }
                                onFocus={() =>
                                    formValues?.downPayment === undefined &&
                                    analyticsQuestionViewed('downPayment', 4)
                                }
                            />
                        </Box>
                        <Box width={[1, 0.4]} mt={[2, 0]}>
                            <Percentage data-test-id="downpaymentPercentage">
                                {downpaymentPercentage}%
                            </Percentage>
                            <PropertyValueText tx="ofPropertyValue" />
                        </Box>
                    </Flex>
                    {downpaymentInfo && (
                        <Flex mt={2} flexDirection="row" alignItems="center">
                            <BubbleMessage
                                text={downpaymentInfo}
                                topArrowPosition={TopArrowPositions.left}
                                icon={
                                    <ExclamationSvg
                                        fillColor={theme.colors.sunsetOrange}
                                    />
                                }
                                bubbleType={BubbleType.error}
                            />
                        </Flex>
                    )}
                </Flex>
            </Section>
            <Section
                title="getAQuote.purchaseProx"
                disabled={!formValues?.propertyType || !!downpaymentInfo}
            >
                <SelectField
                    name="timing"
                    placeholder="select"
                    isRequired
                    options={APPLICATION_TIMING}
                    onChange={value => {
                        if (hideAcceptanceQuestion && value) {
                            change(
                                'acceptanceDate',
                                TimingAcceptanceDates[value]
                            );
                            analyticsQuestionAnswered('timing', 5, value);
                        }
                    }}
                    onFocus={() =>
                        formValues?.timing === undefined &&
                        analyticsQuestionViewed('timing', 5)
                    }
                />
            </Section>
            {!hideAcceptanceQuestion && (
                <Section
                    title={
                        formValues?.timing !== Timings.MADE_OFFER
                            ? 'getAQuote.dateMakingOffer'
                            : 'getAQuote.acceptanceDateMadeOffer'
                    }
                    disabled={!formValues?.timing}
                >
                    <DateField
                        name="acceptanceDate"
                        isRequired
                        showMonthYear={
                            formValues?.timing !== Timings.MADE_OFFER
                        }
                        validate={
                            formValues?.timing !== Timings.MADE_OFFER
                                ? [Validators.isCurrentMonthOrFuture]
                                : [
                                      Validators.isTodayOrPastDate,
                                      Validators.dateExceedsOneYear(new Date())
                                  ]
                        }
                    />
                </Section>
            )}
            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapper>
    );
};

export const NewMortgage = compose<React.FC>(
    connect<StateProps, DispatchProps>(
        (state: any) => ({
            initialValues: getWizardInitialValues(state),
            formValues: getFormValues(WIZARDS.NEW_MORTGAGE_QUOTE)(
                state
            ) as MortgageFormValues,
            downpaymentPercentage: newMortgageDownPaymentPercentage(state),
            downpaymentInfo: newMortgageDownpaymentInformation(state),
            isUserLoggedIn: isLoggedIn(state)
        }),
        {
            updateInitialValues: initialValues =>
                Actions.updateInitialValues(initialValues),
            submitForm: () => Actions.submitNew(WIZARDS.NEW_MORTGAGE_QUOTE),
            analyticsQuestionAnswered: (step, index, value) =>
                Actions.analyticsQuestionAnswered(
                    WIZARDS.NEW_MORTGAGE_QUOTE,
                    step,
                    index,
                    value
                ),
            analyticsQuestionViewed: (step, index) =>
                Actions.analyticsQuestionViewed(
                    WIZARDS.NEW_MORTGAGE_QUOTE,
                    step,
                    index
                )
        }
    ),
    reduxForm({
        form: WIZARDS.NEW_MORTGAGE_QUOTE,
        enableReinitialize: true, // async region fetch may come after component rendering
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    }),
    withTheme
)(NewMortgageView);
