import React, { useMemo, useState, useEffect } from 'react';
import { Flex } from 'reflexbox/styled-components';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Actions } from 'reducers/mortgage-wizards.redux';
import { WIZARDS } from 'constants/appConstants';
import { Typograph } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { SelectField, CurrencyField } from 'components/fields';
import { TextField } from 'components/fields/text-input/text-input.component';
import { maxLength, validateRefinanceMinimum } from 'lib/validators';
import { isRefinanceValidAmount } from 'reducers/mortgage-wizards.selectors';
import { LENDER_SELECT_OPTIONS } from 'constants/lenderConstants';
import { isLoggedIn } from 'reducers/auth.selectors';
import {
    PopupErrorMessage,
    ModalState
} from 'components/get-a-quote/minimum-financing-message.component';
import { getAvailableWithdrawalAmount } from 'utils/mortgage-utils';
import { getUserLanguage } from 'reducers/account.selectors';
import { PageWrapperGaqSf } from 'components/get-a-quote-sf/layout';
import { Section } from 'components/get-a-quote/section-component';
import { useQueryString } from 'utils/hooks';
import { MortgageFormValues } from 'types/mortgage.interface';
import { getWizardInitialValues } from 'reducers/mortgage-wizards.selectors';
import { Actions as AccountActions } from 'reducers/account.redux';

type StateProps = {
    initialValues: {};
};

export const RefinanceView = ({ change, ...rest }: InjectedFormProps) => {
    const [modal, setModal] = useState<ModalState | null>(null);
    const [logoutUser, setLogoutUser] = useState(true);
    const dispatch = useDispatch();

    const formValues = useSelector(
        getFormValues(WIZARDS.REFINANCE_MORTGAGE_QUOTE)
    ) as MortgageFormValues;

    const isRefinanceValid = useSelector(isRefinanceValidAmount);
    const isUserLoggedIn = useSelector(isLoggedIn);
    const language = useSelector(getUserLanguage);

    const memorizedValidator = useMemo(
        () => () =>
            isRefinanceValid
                ? undefined
                : 'getAQuote.refinanceFundsErrorExceed',
        [isRefinanceValid]
    );

    // const logout = () => {};

    const {
        propertyPrice,
        purpose,
        lender,
        additionalFundAmount,
        mortgageAmount,
        firstName,
        lastName,
        phone,
        province,
        email
    } = useQueryString();

    useEffect(() => {
        // logout the user first
        if (isUserLoggedIn && logoutUser) {
            setLogoutUser(false);
            dispatch(AccountActions.logout());
        }

        dispatch(
            Actions.updateInitialValues({
                propertyValue: +propertyPrice,
                ownerOccupied: purpose?.toUpperCase() === 'OWNER_OCCUPIED',
                lender,
                additionalFundAmount: +additionalFundAmount,
                mortgageAmount: +mortgageAmount,

                contact: {
                    firstName,
                    lastName,
                    phone,
                    province,
                    email
                }
            })
        );
    }, [
        propertyPrice,
        purpose,
        lender,
        additionalFundAmount,
        mortgageAmount,
        firstName,
        lastName,
        phone,
        province,
        email,
        isUserLoggedIn,
        dispatch,
        logoutUser,
        setLogoutUser
    ]);

    const amount = useMemo(
        () =>
            getAvailableWithdrawalAmount({
                language,
                propertyValue: formValues?.propertyValue || 0,
                isOwnerOccupied: formValues?.ownerOccupied,
                mortgageAmount: formValues?.mortgageAmount || 0
            }),
        [formValues, language]
    );

    const submitHandler = () => {
        const isMinimumRequirement = validateRefinanceMinimum(
            formValues?.mortgageAmount,
            formValues?.additionalFundAmount
        );
        if (!isMinimumRequirement) {
            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });
            return;
        }

        setLogoutUser(false);

        dispatch(Actions.submitRefinance(WIZARDS.REFINANCE_MORTGAGE_QUOTE));
    };

    return (
        <PageWrapperGaqSf
            id="refinance"
            title="getAQuote.browserNewMortgageTitle"
            onSubmit={submitHandler}
            change={change}
            {...rest}
        >
            <Typograph
                tx="getAQuote.refinanceConfirmGaqValues"
                mt={0}
                fontSize={22}
                fontWeight={700}
            />
            <Section title="getAQuoteRefinance.live">
                <RadioGroupField name="ownerOccupied" />
            </Section>
            <Section title="getAQuoteRenewal.propertyValue">
                <CurrencyField
                    placeholder="propertyValue"
                    isRequired
                    name="propertyValue"
                />
            </Section>
            <Section title="getAQuote.mortgageBalance">
                <CurrencyField
                    placeholder="mortgageBalance"
                    isRequired
                    name="mortgageAmount"
                    maxValue={formValues?.propertyValue}
                />
            </Section>
            <Section title="getAQuoteRenewal.currentMortgageLender">
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="lender"
                        placeholder="lender"
                        options={LENDER_SELECT_OPTIONS}
                        sort
                        isRequired
                    />
                    {formValues?.lender === 'OTHER' && (
                        <TextField
                            mt={[2, 0]}
                            ml={[0, 2]}
                            name="lenderOther"
                            isRequired
                            validate={[maxLength(36)]}
                            placeholder="getAQuoteRenewal.lenderOther"
                        />
                    )}
                </Flex>
            </Section>
            <Section title="getAQuote.refinanceValue">
                <Flex flexDirection={['column', 'row']}>
                    <CurrencyField
                        mt={0}
                        dataTestId="additionalFundsField"
                        placeholder="application.renewingProperty.additionalFundAmount"
                        isRequired
                        maxValue={formValues?.propertyValue}
                        name="additionalFundAmount"
                        validate={[memorizedValidator]}
                        errorValues={{ amount }}
                    />
                    <Typograph
                        mt={[2, 0]}
                        ml={[0, 2]}
                        tx={
                            formValues?.ownerOccupied
                                ? 'getAQuote.refinanceFundsError80'
                                : 'getAQuote.refinanceFundsError75'
                        }
                        values={{ amount }}
                        setInnerHtml
                    />
                </Flex>
            </Section>

            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapperGaqSf>
    );
};

export const RefinanceSf = compose<React.FC>(
    connect<StateProps>(state => ({
        initialValues: getWizardInitialValues(state)
    })),
    reduxForm({
        form: WIZARDS.REFINANCE_MORTGAGE_QUOTE,
        enableReinitialize: true, // async region fetch may come after component rendering
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    })
)(RefinanceView);
