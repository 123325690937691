import React from 'react';
import { Flex } from 'reflexbox/styled-components';
import { Form, InjectedFormProps } from 'redux-form';

import { SubmitButton } from 'components/buttons/button';
import { CardWizard } from 'components/card/card-wizard.component';
import { Typograph } from 'components/typograph';
import { PageContainer } from 'components/page-container';
import { BrowserTitle } from 'utils/browser-title';
import { ContactFields } from 'components/get-a-quote/contact.component';
import { useMessages } from 'utils/hooks';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import { AccountCreateCopyType } from 'components/wizard-steps/general-components/unique-selling-propositions';

type LayoutProps = {
    title?: string;
    children: React.ReactNode;
};

export const Layout = ({ title, children }: LayoutProps) => (
    <BrowserTitle title="sidebar.getAQuote" subsection={title}>
        <PageContainer flexDirection="column" alignItems="center">
            <Flex
                width="100%"
                alignItems={['center', 'center']}
                justifyContent={['center', 'center']}
                flexDirection="column"
                pb={[0, 3]}
                minHeight={['100%', '85vh']}
            >
                {children}
            </Flex>
        </PageContainer>
    </BrowserTitle>
);

type WrapperProps = {
    id: string;
    onSubmit: () => void;
} & LayoutProps &
    InjectedFormProps;

export const PageWrapperGaqSf = ({
    id,
    title,
    handleSubmit,
    onSubmit,
    submitting,
    form,
    children
}: WrapperProps) => {
    const t = useMessages();
    const accountCreationFlag = useFeatureFlag<AccountCreateCopyType>(
        'account-creation-cta'
    );
    const isAccountCreationTestActive =
        !!accountCreationFlag && accountCreationFlag !== 'FLAG_OFF';

    const defaultCopy = 'seeRates';
    const submitCopy = isAccountCreationTestActive
        ? 'account.creation.' + accountCreationFlag
        : defaultCopy;

    return (
        <Layout title={title}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <CardWizard id={id}>
                    {children}

                    <Flex mt={3} flexDirection="column">
                        <ContactFields
                            form={form}
                            label="getAQuote.confirmAccountValues"
                        />
                    </Flex>

                    <Flex
                        flexDirection="column"
                        alignItems="center"
                        px={[15, 20]}
                    >
                        <SubmitButton
                            my={3}
                            label={submitCopy}
                            submitting={submitting}
                            width="100%"
                            maxWidth="100%"
                            mx={5}
                        />
                        <Typograph
                            as="p"
                            tx={
                                isAccountCreationTestActive
                                    ? 'createAccountAgreement.test'
                                    : 'createAccountAgreement'
                            }
                            values={{
                                copy: t(
                                    'account.creation.' + accountCreationFlag
                                )
                            }}
                            fontSize={0}
                            setInnerHtml
                            mt={1}
                        />
                    </Flex>
                </CardWizard>
            </Form>
        </Layout>
    );
};
