import { forwardRef } from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components/macro';
import {
    space,
    width,
    maxWidth,
    alignSelf,
    SpaceProps,
    WidthProps,
    MaxWidthProps,
    FontSizeProps,
    AlignSelfProps
} from 'styled-system';

import { Colors } from '@nesto/themes';
import { Tick } from 'assets/media/icons/Tick';
import { Typograph, TypographI18nKeys } from 'components/typograph';
import { normalizeInputNameForE2E } from 'utils/e2e-utilities';
import { useTheme } from 'utils/hooks';
import { InputError } from 'components/inputs/input-error/input-error.component';

type StyledProps = WidthProps &
    SpaceProps &
    MaxWidthProps &
    FontSizeProps &
    AlignSelfProps;

type Props = {
    label?: TypographI18nKeys;
    isRequired?: boolean;
    labelColor?: Colors;
    theme?: any;
    defaultChecked?: any;
    hideInputErrors?: boolean;
    checked?: any;
    showErrorOnTouched?: boolean;
    copyText?: TypographI18nKeys;
};

type CheckboxWrapperProps = {
    classStyle: 'error' | 'default';
    theme?: any;
};

const CheckboxWrapper = styled.div<CheckboxWrapperProps & AlignSelfProps>`
    ${props => props.theme.input.border};
    position: relative;
    width: 27px;
    height: 25px;
    border-color: ${({ theme, classStyle }: CheckboxWrapperProps) =>
        theme.input[classStyle].borderColor};
    background-color: white;
    ${alignSelf};
    ${space};
    ${width};
    ${maxWidth};
`;

const Input = styled.input`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 25px;
    height: 25px;
    margin: 0px;
    padding: 0px;
    opacity: 0;
    z-index: ${props => props.theme.zIndex.floor};
`;

const TickWrapper = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: ${props => props.theme.zIndex.house};
`;

const LabelContainer = styled(Typograph)`
    display: flex;
    justify-content: flex-start;
    margin-left: ${props => props.theme.space[1]}px;
    width: 100%;
    cursor: pointer;
    & > * {
        pointer-events: none;
    }
`;

const CheckboxContainer = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${space};
    ${width};
    ${maxWidth};
`;

export const returnClass = meta => {
    if (meta.touched && meta.error) {
        return 'error';
    }

    return 'default';
};

export const Checkbox = forwardRef(
    (
        {
            label,
            input,
            meta,
            labelColor,
            hideInputErrors = false,
            isRequired,
            fontSize,
            alignSelf,
            defaultChecked,
            checked,
            showErrorOnTouched = false,
            copyText,
            ...props
        }: Props & WrappedFieldProps & StyledProps,
        inRef
    ) => {
        const theme = useTheme();
        const classStyle = returnClass(meta);

        return (
            <>
                <CheckboxContainer
                    htmlFor={`checkbox_${input.name}`}
                    {...props}
                    data-test-id={`checkbox-container-${normalizeInputNameForE2E(
                        input.name || ''
                    )}`}
                >
                    <CheckboxWrapper
                        data-test-id={`checkbox_wrapper_${input.name}`}
                        classStyle={classStyle}
                        alignSelf={alignSelf}
                    >
                        {(checked || input.checked) && (
                            <TickWrapper>
                                <Tick fillColor={theme.brand.accent} />
                            </TickWrapper>
                        )}
                        <Input
                            // @ts-ignore
                            ref={inRef}
                            id={`checkbox_${input.name}`}
                            type="checkbox"
                            data-test-id={normalizeInputNameForE2E(
                                input.name || ''
                            )}
                            defaultChecked={defaultChecked || checked}
                            {...input}
                        />
                    </CheckboxWrapper>
                    {label && (
                        <LabelContainer
                            setInnerHtml
                            tx={label}
                            color={labelColor || theme.input.default.color}
                            fontSize={fontSize || theme.fontSizes[1]}
                            data-test-id={`checkbox_label_${input.name}`}
                        />
                    )}
                </CheckboxContainer>
                {copyText && <Typograph setInnerHtml tx={copyText} />}
                {!hideInputErrors && (
                    <InputError
                        meta={meta}
                        name={input.name}
                        showErrorOnTouched={showErrorOnTouched}
                    />
                )}
            </>
        );
    }
);
