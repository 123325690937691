import React, { useMemo, useState } from 'react';
import { Flex } from 'reflexbox/styled-components';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { Actions } from 'reducers/mortgage-wizards.redux';
import { WIZARDS } from 'constants/appConstants';
import { Typograph } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { SelectField, CurrencyField } from 'components/fields';
import { TextField } from 'components/fields/text-input/text-input.component';
import { maxLength, validateRefinanceMinimum } from 'lib/validators';
import { isRefinanceValidAmount } from 'reducers/mortgage-wizards.selectors';
import { LENDER_SELECT_OPTIONS } from 'constants/lenderConstants';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import { isLoggedIn } from 'reducers/auth.selectors';
import {
    PopupErrorMessage,
    ModalState
} from './minimum-financing-message.component';
import { getAvailableWithdrawalAmount } from 'utils/mortgage-utils';
import { getUserLanguage } from 'reducers/account.selectors';
import { PageWrapper } from './layout';
import { Section } from './section-component';

type StateProps = {
    formValues: any;
    isRefinanceValid: boolean;
    isUserLoggedIn: boolean;
    language: string;
};

type DispatchProps = {
    submitForm: () => void;
    analyticsQuestionViewed: (currentStep: string, index: number) => void;
    analyticsQuestionAnswered: (
        currentStep: string,
        index: number,
        value: any
    ) => void;
};

export const RefinanceView = ({
    formValues,
    submitForm,
    analyticsQuestionAnswered,
    analyticsQuestionViewed,
    isRefinanceValid,
    isUserLoggedIn,
    language,
    ...rest
}: StateProps & DispatchProps & InjectedFormProps) => {
    const [modal, setModal] = useState<ModalState | null>(null);
    const [showContact, setShowContact] = useState(false);
    const isAnonRates = useFeatureFlag('anonymous-rates-page');

    const memorizedValidator = useMemo(
        () => () =>
            isRefinanceValid
                ? undefined
                : 'getAQuote.refinanceFundsErrorExceed',
        [isRefinanceValid]
    );

    const amount = useMemo(
        () =>
            getAvailableWithdrawalAmount({
                language,
                propertyValue: formValues?.propertyValue || 0,
                isOwnerOccupied: formValues?.ownerOccupied,
                mortgageAmount: formValues?.mortgageAmount || 0
            }),
        [formValues, language]
    );

    const submitHandler = () => {
        const isMinimumRequirement = validateRefinanceMinimum(
            formValues?.mortgageAmount,
            formValues?.additionalFundAmount
        );
        if (!isMinimumRequirement) {
            analyticsQuestionViewed('minimum_requirement', 5);

            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });
            return;
        }

        if (!isUserLoggedIn && !isAnonRates && !showContact) {
            analyticsQuestionViewed('contact', 8);

            setShowContact(true);
            return;
        }

        if (showContact) {
            analyticsQuestionAnswered('contact', 8, formValues);
        }

        submitForm();
    };

    return (
        <PageWrapper
            id="refinance"
            title="getAQuote.browserRefinanceTitle"
            showContact={showContact}
            onSubmit={submitHandler}
            {...rest}
        >
            <Section title="getAQuoteRefinance.live">
                <RadioGroupField
                    name="ownerOccupied"
                    onFocus={() =>
                        formValues?.ownerOccupied === undefined &&
                        analyticsQuestionViewed('ownerOccupied', 1)
                    }
                    onChange={input => {
                        analyticsQuestionAnswered(
                            'ownerOccupied',
                            1,
                            input?.target.value
                        );
                    }}
                />
            </Section>
            <Section
                title="getAQuoteRenewal.propertyValue"
                disabled={formValues?.ownerOccupied === undefined}
            >
                <CurrencyField
                    placeholder="propertyValue"
                    isRequired
                    name="propertyValue"
                    onFocus={() =>
                        formValues?.propertyValue === undefined &&
                        analyticsQuestionViewed('propertyValue', 2)
                    }
                    onBlur={() =>
                        formValues?.propertyValue > 0 &&
                        analyticsQuestionAnswered(
                            'propertyValue',
                            2,
                            formValues.propertyValue
                        )
                    }
                />
            </Section>
            <Section
                title="getAQuote.mortgageBalance"
                disabled={!formValues?.propertyValue}
            >
                <CurrencyField
                    placeholder="mortgageBalance"
                    isRequired
                    name="mortgageAmount"
                    maxValue={formValues?.propertyValue}
                    onFocus={() =>
                        formValues?.mortgageAmount === undefined &&
                        analyticsQuestionViewed('mortgageAmount', 3)
                    }
                    onBlur={() =>
                        formValues?.mortgageAmount > 0 &&
                        analyticsQuestionAnswered(
                            'mortgageAmount',
                            3,
                            formValues.mortgageAmount
                        )
                    }
                />
            </Section>
            <Section
                title="getAQuoteRenewal.currentMortgageLender"
                disabled={!formValues?.mortgageAmount}
            >
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="lender"
                        placeholder="lender"
                        options={LENDER_SELECT_OPTIONS}
                        sort
                        isRequired
                        disabled={!formValues?.mortgageAmount}
                        onFocus={() =>
                            formValues?.lender === undefined &&
                            analyticsQuestionViewed('lender', 4)
                        }
                        onChange={input => {
                            analyticsQuestionAnswered('lender', 4, input);
                        }}
                    />
                    {formValues?.lender === 'OTHER' && (
                        <TextField
                            mt={[2, 0]}
                            ml={[0, 2]}
                            name="lenderOther"
                            isRequired
                            validate={[maxLength(36)]}
                            placeholder="getAQuoteRenewal.lenderOther"
                            onFocus={() =>
                                formValues?.lenderOther === undefined &&
                                analyticsQuestionViewed('lenderOther', 4)
                            }
                            onBlur={() =>
                                analyticsQuestionAnswered(
                                    'lenderOther',
                                    4,
                                    formValues.lenderOther
                                )
                            }
                        />
                    )}
                </Flex>
            </Section>
            <Section
                title="getAQuote.refinanceValue"
                disabled={!formValues?.lender}
            >
                <Flex flexDirection={['column', 'row']}>
                    <CurrencyField
                        mt={0}
                        dataTestId="additionalFundsField"
                        placeholder="application.renewingProperty.additionalFundAmount"
                        isRequired
                        disabled={!formValues?.lender}
                        maxValue={formValues?.propertyValue}
                        name="additionalFundAmount"
                        onFocus={() =>
                            formValues?.additionalFundAmount === undefined &&
                            analyticsQuestionViewed('additionalFundAmount', 5)
                        }
                        validate={[memorizedValidator]}
                        errorValues={{ amount }}
                        onBlur={() =>
                            analyticsQuestionAnswered(
                                'additionalFundAmount',
                                5,
                                formValues.additionalFundAmount
                            )
                        }
                    />
                    <Typograph
                        mt={[2, 0]}
                        ml={[0, 2]}
                        tx={
                            formValues?.ownerOccupied
                                ? 'getAQuote.refinanceFundsError80'
                                : 'getAQuote.refinanceFundsError75'
                        }
                        values={{ amount }}
                        setInnerHtml
                    />
                </Flex>
            </Section>

            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapper>
    );
};

export const Refinance = compose<React.FC>(
    connect<StateProps, DispatchProps>(
        state => ({
            formValues: getFormValues(WIZARDS.REFINANCE_MORTGAGE_QUOTE)(state),
            isRefinanceValid: isRefinanceValidAmount(state),
            isUserLoggedIn: isLoggedIn(state),
            language: getUserLanguage(state)
        }),
        dispatch => ({
            submitForm: () =>
                dispatch(
                    Actions.submitRefinance(WIZARDS.REFINANCE_MORTGAGE_QUOTE)
                ),
            analyticsQuestionViewed: (step, index) =>
                dispatch(
                    Actions.analyticsQuestionViewed(
                        WIZARDS.REFINANCE_MORTGAGE_QUOTE,
                        step,
                        index
                    )
                ),
            analyticsQuestionAnswered: (step, index, value) =>
                dispatch(
                    Actions.analyticsQuestionAnswered(
                        WIZARDS.REFINANCE_MORTGAGE_QUOTE,
                        step,
                        index,
                        value
                    )
                )
        })
    ),
    reduxForm({
        form: WIZARDS.REFINANCE_MORTGAGE_QUOTE,
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    })
)(RefinanceView);
