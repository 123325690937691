import React from 'react';
import { withTheme, ThemeProps } from 'styled-components';
import { Theme } from '@nesto/themes';

type Props = {
    size?: number;
    fillColor?: string;
};

const ExclamationSvgView = ({
    size = 50,
    fillColor,
    theme
}: Props & ThemeProps<Theme>) => {
    const fill = fillColor || theme.application.warning;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            data-test-id="exclamation-svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            xmlSpace="preserve"
        >
            <g id="Hard_fill_icon_Alert">
                <path
                    fill={fill}
                    id="Combined-Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20,0c11,0,20,9,20,20s-9,20-20,20S0,31,0,20S9,0,20,0z
		 M20,25.8c-0.8,0-1.5,0.3-2.1,0.8s-0.8,1.1-0.8,1.9s0.3,1.4,0.8,1.9c0.6,0.5,1.2,0.8,2.1,0.8c0.8,0,1.5-0.3,2.1-0.8
		s0.8-1.2,0.8-1.9s-0.3-1.4-0.8-1.9C21.5,26.1,20.9,25.8,20,25.8z M22.8,10h-5.6l0.9,13.7h3.7L22.8,10z"
                />
            </g>
        </svg>
    );
};

export const ExclamationSvg = withTheme(ExclamationSvgView);
