import React, { useState } from 'react';
import { Flex } from 'reflexbox/styled-components';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { Actions } from 'reducers/mortgage-wizards.redux';
import { WIZARDS, RENEWAL_SCHEDULE } from 'constants/appConstants';
import { Typograph } from 'components/typograph';
import { RadioGroupField } from 'components/fields/radio-switch/radio-group-field.component';
import { SelectField, CurrencyField } from 'components/fields';
import { TextField } from 'components/fields/text-input/text-input.component';
import { maxLength, validateRenewalMinimum } from 'lib/validators';
import { LENDER_SELECT_OPTIONS } from 'constants/lenderConstants';
import { useFeatureFlag } from 'components/feature-flagger/hooks';
import { isLoggedIn } from 'reducers/auth.selectors';
import {
    PopupErrorMessage,
    ModalState
} from './minimum-financing-message.component';
import { DOWN_PAYMENT_RATIO } from 'constants/ratesConstants';
import { PageWrapper } from './layout';
import { Section } from './section-component';
import { validateUnderwater95, validateUnderwater80 } from './validators';

type StateProps = {
    formValues: any;
    isUserLoggedIn: boolean;
};

type DispatchProps = {
    submitForm: () => void;
    analyticsQuestionViewed: (currentStep: string, index: number) => void;
    analyticsQuestionAnswered: (
        currentStep: string,
        index: number,
        value: any
    ) => void;
};

const RenewalView = ({
    formValues,
    submitForm,
    analyticsQuestionAnswered,
    analyticsQuestionViewed,
    isUserLoggedIn,
    ...rest
}: StateProps & DispatchProps & InjectedFormProps) => {
    const [modal, setModal] = useState<ModalState | null>(null);
    const [showContact, setShowContact] = useState(false);
    const isAnonRates = useFeatureFlag('anonymous-rates-page');

    const submitHandler = () => {
        const isMinimumRequirement = validateRenewalMinimum(
            formValues?.mortgageAmount
        );
        if (!isMinimumRequirement) {
            analyticsQuestionViewed('minimum_requirement', 5);

            setModal({
                title: 'getAQuote.minimumRequirementTitle',
                content: 'minimumRequirementText'
            });
            return;
        }

        const isMortgageUnderwater95 = validateUnderwater95(
            formValues?.propertyValue,
            formValues?.mortgageAmount
        );
        if (isMortgageUnderwater95) {
            analyticsQuestionViewed('minimum_requirement', 5);

            setModal({
                title: 'getAQuote.underwater95Title',
                content: 'getAQuote.underwater95Text'
            });
            return;
        }

        const isMortgageUnderwater80 = validateUnderwater80(
            formValues?.downPaymentRatio,
            formValues?.propertyValue,
            formValues?.mortgageAmount
        );
        if (isMortgageUnderwater80) {
            analyticsQuestionViewed('minimum_requirement', 5);

            setModal({
                title: 'getAQuote.underwater80Title',
                content: 'getAQuote.underwater80Text'
            });
            return;
        }

        if (!isUserLoggedIn && !isAnonRates && !showContact) {
            analyticsQuestionViewed('contact', 8);
            setShowContact(true);
            return;
        }

        if (showContact) {
            analyticsQuestionAnswered('contact', 8, formValues);
        }

        submitForm();
    };

    return (
        <PageWrapper
            id="renewal"
            title="getAQuote.browserRenewalTitle"
            showContact={showContact}
            onSubmit={submitHandler}
            {...rest}
        >
            <Section title="getAQuoteRenewal.live">
                <RadioGroupField
                    name="ownerOccupied"
                    onChange={input => {
                        analyticsQuestionAnswered(
                            'ownerOccupied',
                            1,
                            input?.target.value
                        );
                    }}
                    onFocus={() =>
                        formValues?.ownerOccupied === undefined &&
                        analyticsQuestionViewed('ownerOccupied', 1)
                    }
                />
            </Section>
            <Section
                title="getAQuoteRenewal.propertyValue"
                disabled={formValues?.ownerOccupied === undefined}
            >
                <CurrencyField
                    placeholder="propertyValue"
                    isRequired
                    name="propertyValue"
                    onBlur={() =>
                        formValues?.propertyValue > 0 &&
                        analyticsQuestionAnswered(
                            'propertyValue',
                            2,
                            formValues.propertyValue
                        )
                    }
                    onFocus={() =>
                        formValues?.propertyValue === undefined &&
                        analyticsQuestionViewed('propertyValue', 2)
                    }
                />
            </Section>
            <Section
                title="getAQuote.mortgageBalance"
                disabled={!formValues?.propertyValue}
            >
                <CurrencyField
                    placeholder="mortgageBalance"
                    isRequired
                    name="mortgageAmount"
                    maxValue={formValues?.propertyValue}
                    onBlur={() =>
                        formValues?.mortgageAmount > 0 &&
                        analyticsQuestionAnswered(
                            'mortgageAmount',
                            3,
                            formValues.mortgageAmount
                        )
                    }
                    onFocus={() =>
                        formValues?.mortgageAmount === undefined &&
                        analyticsQuestionViewed('mortgageAmount', 3)
                    }
                />
            </Section>
            <Section
                title="getAQuote.insuredByCmhc"
                disabled={!formValues?.mortgageAmount}
                setInnerHtml={true}
            >
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="downPaymentRatio"
                        placeholder="select"
                        isRequired
                        options={DOWN_PAYMENT_RATIO}
                        onChange={input => {
                            analyticsQuestionAnswered(
                                'downPaymentRatio',
                                4,
                                input
                            );
                        }}
                        onFocus={() =>
                            formValues?.downPaymentRatio === undefined &&
                            analyticsQuestionViewed('downPaymentRatio', 4)
                        }
                    />
                    <Typograph
                        mt={[2, 0]}
                        ml={[0, 2]}
                        tx="getAQuote.insuredByCmhcText"
                    />
                </Flex>
            </Section>
            <Section
                title="getAQuoteRenewal.currentMortgageLender"
                disabled={!formValues?.downPaymentRatio}
            >
                <Flex flexDirection={['column', 'row']}>
                    <SelectField
                        mt={0}
                        name="lender"
                        placeholder="lender"
                        options={LENDER_SELECT_OPTIONS}
                        sort
                        isRequired
                        disabled={!formValues?.downPaymentRatio}
                        onChange={input => {
                            analyticsQuestionAnswered('lender', 5, input);
                        }}
                        onFocus={() =>
                            formValues?.lender === undefined &&
                            analyticsQuestionViewed('lender', 5)
                        }
                    />
                    {formValues?.lender === 'OTHER' && (
                        <TextField
                            mt={[2, 0]}
                            ml={[0, 2]}
                            name="lenderOther"
                            isRequired
                            validate={[maxLength(36)]}
                            placeholder="getAQuoteRenewal.lenderOther"
                            onBlur={() =>
                                analyticsQuestionAnswered(
                                    'lenderOther',
                                    5,
                                    formValues.lenderOther
                                )
                            }
                            onFocus={() =>
                                formValues?.lenderOther === undefined &&
                                analyticsQuestionViewed('lenderOther', 5)
                            }
                        />
                    )}
                </Flex>
            </Section>
            <Section
                title="getAQuoteRenewal.renewalSchedule"
                disabled={!formValues?.lender}
            >
                <SelectField
                    name="renewalSchedule"
                    placeholder="select"
                    options={RENEWAL_SCHEDULE}
                    isRequired
                    onChange={input => {
                        analyticsQuestionAnswered('renewalSchedule', 6, input);
                    }}
                    onFocus={() =>
                        formValues?.renewalSchedule === undefined &&
                        analyticsQuestionViewed('renewalSchedule', 6)
                    }
                />
            </Section>

            <PopupErrorMessage
                showModal={!!modal}
                onCloseComplete={() => setModal(null)}
                title={modal?.title}
                content={modal?.content}
            />
        </PageWrapper>
    );
};

export const Renewal = compose<React.FC>(
    connect<StateProps, DispatchProps>(
        state => ({
            formValues: getFormValues(WIZARDS.RENEWAL_MORTGAGE_QUOTE)(state),
            isUserLoggedIn: isLoggedIn(state)
        }),
        dispatch => ({
            submitForm: () =>
                dispatch(Actions.submitRenewal(WIZARDS.RENEWAL_MORTGAGE_QUOTE)),
            analyticsQuestionViewed: (step, index) =>
                dispatch(
                    Actions.analyticsQuestionViewed(
                        WIZARDS.RENEWAL_MORTGAGE_QUOTE,
                        step,
                        index
                    )
                ),
            analyticsQuestionAnswered: (step, index, value) =>
                dispatch(
                    Actions.analyticsQuestionAnswered(
                        WIZARDS.RENEWAL_MORTGAGE_QUOTE,
                        step,
                        index,
                        value
                    )
                )
        })
    ),
    reduxForm({
        form: WIZARDS.RENEWAL_MORTGAGE_QUOTE,
        forceUnregisterOnUnmount: true, // this is important for a wizard.
        destroyOnUnmount: false // this is important for a wizard.
    })
)(RenewalView);
