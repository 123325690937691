import { downPaymentRatios } from 'constants/ratesConstants';

export const validateUnderwater95 = (
    propertyValue: number,
    mortgageAmount: number
) => mortgageAmount / propertyValue > 0.95;

export const validateUnderwater80 = (
    downPaymentRatio: downPaymentRatios,
    propertyValue: number,
    mortgageAmount: number
) =>
    downPaymentRatio === downPaymentRatios.TWENTY_MORE &&
    mortgageAmount / propertyValue > 0.8;

export const isNotZero = (value: number) =>
    value === 0 || value === undefined ? 'nozero' : undefined;
