import * as React from 'react';
import styled, { withTheme, css } from 'styled-components/macro';
import { Flex } from 'reflexbox/styled-components';
import {
    variant,
    space,
    width,
    WidthProps,
    SpaceProps,
    AlignItemsProps
} from 'styled-system';

type Props = {
    id?: string;
    children?: any;
    arrowProps?: any;
    bgColor?: string;
    theme: any;
    contentProps?: any;
};

type StyledProps = WidthProps & SpaceProps;

const MessageContainer = styled(Flex)`
    position: relative;
    color: ${props => props.theme.colors.white};
    ${({ isInside, theme }) =>
        isInside
            ? css`
                  padding: ${theme.space[3]}px;
              `
            : css`
                  padding: 14px 10px 14px 30px;
              `}
    flex-direction: column;
    border-radius: ${props => props.theme.borderRadius};
    min-height: ${props => props.theme.space[5]}px;
    margin-left: 18px;
    ${space};
    ${width};
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction: column;
    }

    ${variant({ scale: 'messages' })}
`;

// @ts-ignore
const MessageArrow = styled(Flex)`
    position: absolute;
    right: 0;
    bottom: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0px 32px 22px 0;
    border-color: transparent
        ${({ theme, variant }) => theme.messages[variant].backgroundColor}
        transparent transparent;
`;

const Content = styled(Flex)`
    height: 100%;
    margin-top: ${({ isInside, theme }) =>
        isInside ? theme.space[3] + 40 : theme.space[3]}px;
    justify-content: ${({ isInside, theme }) =>
        isInside ? 'center' : undefined};

    ${space}
`;

const MessageView = ({
    id = 'default',
    children,
    arrowProps,
    contentProps,
    bgColor,
    theme,
    ...styledProps
}: Props & StyledProps & AlignItemsProps) => (
    <MessageContainer
        data-test-id={`messageContainer_${id}`}
        {...styledProps}
        variant="primary"
    >
        <MessageArrow
            variant="primary"
            arrowProps={arrowProps}
            bgColor={bgColor ? bgColor : theme.colors.portGore}
        />

        <Content flexDirection="column" {...contentProps}>
            {children}
        </Content>
    </MessageContainer>
);

export const Message = withTheme(MessageView);
