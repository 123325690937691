import React, { useContext, useCallback } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { Box, Flex } from 'reflexbox/styled-components';
import { compose } from 'redux';
import styled from 'styled-components';
import { PROVINCES } from 'constants/appConstants';
import { Checkbox } from 'components/inputs/checkbox';
import { FeatureFlagger } from 'components/feature-flagger';
import {
    PhoneField,
    TextField,
    EmailField,
    SelectField
} from 'components/fields';
import { PartnerContext } from 'app-root/providers/PartnerProvider';
import { DefaultValueField } from 'components/fields/default-value-field/default-value-field.component';
import { withSizesHoc as WithSizes, WithSizesProps } from 'hocs/with-sizes.hoc';
import { Typograph } from 'components/typograph';
import { Image } from 'components/image';
import * as Validators from 'lib/validators';
import { PARTNER_NAMES } from 'constants/appConstants';
import {
    getSubPartnerId as subPartnerId,
    getUserLanguage
} from 'reducers/account.selectors';
import { getNestoSecuredLogoSrc } from 'utils/localization-utils';
import { getMortgageContact } from 'reducers/mortgage-wizards.selectors';
import {
    useIsPhoneNumberValid,
    useFeatureFlag
} from 'components/feature-flagger/hooks';
import { FormContact } from 'types/mortgage.interface';
import { Language } from 'types/language';
import { PartnersEmailConsentCheckbox } from 'components/partners-email-consent-checkbox';

type Props = { label?: string } & Pick<InjectedFormProps, 'form'>;

type StateProps = {
    currentLang?: Language;
    contact?: FormContact;
};

const NestoSecureWrapper = styled(Box)`
    width: 100%;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.grey25};
`;

const ContactFieldsView = ({
    isMobile,
    currentLang,
    contact,
    label = 'getAQuote.sendWhere'
}: Props & StateProps & InjectedFormProps & WithSizesProps) => {
    const { partner, config } = useContext(PartnerContext);
    const confirmEmailField = useFeatureFlag('confirm-email-field');
    const gaqPhoneFlag = useFeatureFlag('gaq-phone-number');

    const validateIsPhoneNumberRealFlag = useFeatureFlag<boolean>(
        'validate-is-phone-number-real'
    );

    const isPhoneNumberValid = useIsPhoneNumberValid(
        contact?.phone,
        validateIsPhoneNumberRealFlag
    );

    const memorizedValidator = useCallback(
        () => Validators.realPhoneNumberValidator(isPhoneNumberValid),
        [isPhoneNumberValid]
    );

    return (
        <>
            <Typograph tx={label} mt={0} fontSize={22} fontWeight={700} />

            <Box width="100%" px={[15, 20]}>
                <TextField
                    mt={3}
                    name="contact.firstName"
                    placeholder="getAQuote.firstName"
                    isRequired
                    validate={[Validators.maxFirstNameLength]}
                />
                <TextField
                    mt={2}
                    name="contact.lastName"
                    placeholder="getAQuote.lastName"
                    isRequired
                    validate={[Validators.maxLastNameLength]}
                />

                {gaqPhoneFlag &&
                    (validateIsPhoneNumberRealFlag ? (
                        <PhoneField
                            name="contact.phone"
                            autoComplete="tel"
                            placeholder="phoneNumber.title"
                            isRequired
                            validate={[memorizedValidator]}
                        />
                    ) : (
                        <PhoneField
                            name="contact.phone"
                            autoComplete="tel"
                            placeholder="phoneNumber.title"
                            isRequired
                        />
                    ))}
                <SelectField
                    mt={2}
                    name="contact.province"
                    placeholder="getAQuote.province"
                    options={PROVINCES}
                    isRequired
                />
            </Box>

            <NestoSecureWrapper p={[15, 20]} mt={2}>
                <EmailField
                    mt={0}
                    name="contact.email"
                    placeholder="email"
                    isRequired
                />
                {confirmEmailField && (
                    <EmailField
                        mt={2}
                        name="emailConfirm"
                        autoComplete="email"
                        placeholder="form.emailConfirm"
                        preventCopy
                        preventPaste
                        validate={[Validators.emailsMatch]}
                    />
                )}
                <Flex mt={2} justifyContent="center" alignItems="center">
                    <Image
                        data-test-id="nestoSecured"
                        css={{
                            width: isMobile ? '50%' : '65%'
                        }}
                        src={getNestoSecuredLogoSrc(isMobile, currentLang)}
                        alt="Nesto Secure Encryption"
                    />
                </Flex>
            </NestoSecureWrapper>

            <Box width="100%" px={[15, 20]} pt={20}>
                <FeatureFlagger variation="proprio-direct-consent">
                    {proprioDirectConsent =>
                        proprioDirectConsent &&
                        partner === PARTNER_NAMES.proprioDirect.partner &&
                        subPartnerId ? (
                            <Field
                                mt={2}
                                mb={3}
                                ml={0}
                                name="contact.partnerAgreement"
                                component={Checkbox as any}
                                label={config.partnerAgreement}
                                type="checkbox"
                                fontSize="10px"
                                validate={[Validators.isRequiredCheckedBox]}
                            />
                        ) : null
                    }
                </FeatureFlagger>
            </Box>
            <Box width="100%" px={[15, 20]} pt={20}>
                <PartnersEmailConsentCheckbox
                    formName="SIGNUP"
                    leadDistributeConsentAgreement="contact.leadDistributeConsentAgreement"
                    emailConsent="contact.emailConsent"
                />
            </Box>
            <DefaultValueField name="contact.partner" value={partner} />
        </>
    );
};

export const ContactFields = compose(
    connect((state: any, ownProps: Props) => ({
        currentLang: getUserLanguage(state),
        contact: getMortgageContact(ownProps.form)(state)
    })),
    WithSizes
)(ContactFieldsView) as React.FC<Props>;
